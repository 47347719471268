import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

export function NavigationBar(handleOpenNavMenu, anchorElNav, handleCloseNavMenu, pages) {
    return <AppBar position="static"
        style={{
            background: "black",
            boxShadow: 'none',
            zIndex: 10
        }}
    >
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' }, fontFamily: "Bebas Neue" }}
                >
                    Zaldana.studio
                </Typography>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, fontFamily: "Bebas Neue" }}
                >
                    zaldana.studio
                </Typography>
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.section)}>
                                <Typography sx={{ fontFamily: "Bebas Neue", fontSize: 24}} textAlign="center">{page.name}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (

                        <Button
                            key={page.name}
                            onClick={() => handleCloseNavMenu(page.section)}
                            sx={{ my: 2, color: 'white', display: 'block', fontFamily: "Bebas Neue", fontSize: 18 }}
                        >
                            {page.name}
                        </Button>

                    ))}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>;
}
